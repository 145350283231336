import { ReactNode } from 'react';
import styles from './Card.module.scss';
import { cx } from '@/util/cx';
import { Responsive, getResponsive } from '@/util/getResponsive';

type Props = {
  children: ReactNode;
  desaturate?: boolean;
  padding?: Responsive<0 | 1 | 2 | 3 | 4 | 5 | 6>;
  border?: 1 | 2 | 3 | 4 | 5;
};

export function Card({ children, desaturate, padding, border }: Props) {
  const paddings = getResponsive('pad', padding);

  return (
    <div
      className={cx(
        styles.Card,
        desaturate && styles.desaturate,
        border && styles[`border-${border}`],
        paddings
      )}
    >
      {children}
    </div>
  );
}
