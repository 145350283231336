import { LinkButton } from '@/components/Button';
import { Card } from '@/components/Card';
import { Flex, Grid } from '@/components/Grid';
import { Heading } from '@/components/Heading';
import { Link } from '@/components/Link';
import { Section } from '@/components/Section';
import { Text } from '@/components/Text';
import { useAccount } from '@/resource/account';
import { AdItemType } from '@/resource/ad';
import { staticProps } from '@/util/staticProps';
import { NextSeo } from 'next-seo';

type Props = {
  contactAd: AdItemType | null;
};

export default function NotFound({ contactAd }: Props) {
  const { profile } = useAccount();

  return (
    <>
      <NextSeo title="Pagina niet gevonden" />

      <Section>
        <Grid columns={{ md: [8], lg: [6] }} offsets={{ md: 2, lg: 3 }}>
          <Heading align="center">Pagina Niet Gevonden</Heading>
          <Text align="center" balance>
            Oeps! Het lijkt erop dat we ergens digitaal zijn verdwaald, maar
            geen zorgen, we zijn er nog steeds.
          </Text>

          <Card padding={3}>
            <Flex justify="center">
              <picture>
                <img src={`/svg/illustration/nh_404.svg`} alt="" width={180} />
              </picture>
            </Flex>
          </Card>

          {profile && contactAd ? (
            <>
              <Text align="center" balance>
                Klik op de knop hieronder om met ons te chatten, of ga{' '}
                <Link to={{ type: 'home' }} underline>
                  terug naar de homepage
                </Link>
                .
              </Text>
              <Flex justify="space-between">
                <LinkButton
                  to={{ type: 'nh_chat' }}
                  icon="whatsapp"
                  theme="outline"
                >
                  Chat met ons
                </LinkButton>
                <LinkButton to={{ type: 'home' }}>Home</LinkButton>
              </Flex>
            </>
          ) : (
            <Flex justify="center">
              <LinkButton to={{ type: 'home' }}>Home</LinkButton>
            </Flex>
          )}
        </Grid>
      </Section>
    </>
  );
}

export const getStaticProps = staticProps.resolve<Props>(
  async (ctx, settings) => {
    return {
      props: {
        contactAd: settings.contact_ad?.data || null,
      },
      revalidate: 30,
    };
  }
);
